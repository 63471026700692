<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card>
                <b-card-body class="p-0">
                  <b-form @submit.prevent="onFormSubmit">
                    <b-card-title>
                      What is your non-candidate statement(s)?
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['far', 'file-alt']" />
                        </b-col>
                        <b-col cols="12" lg="8" order="3" order-lg="2" class="text">
                          <div>
                            This is the text of your non-candidate statement
                            <span class="asterisk">*</span>. This is the
                            statement that members can endorse when their
                            eligibility is verified during the myWGA login
                            process. Once you publish the text of your
                            non-candidate statement, you will not be able to
                            change the text in any way.
                          </div>
                          <div class="mt-2 text-muted">
                            <span class="asterisk">*</span>
                            Your non-candidate statement is an endorsement of
                            your candidacy made by eligible WGAW members who are
                            not candidates in the 2022 WGAW Board of Directors
                            election.
                          </div>
                        </b-col>
                        <b-col cols="9" lg="3" order="2" order-lg="3" class="button">
                          <b-button variant="outline-secondary" size="sm" v-b-modal.modal-theme-guide>
                            View Example
                            <font-awesome-icon :icon="['far', 'window-maximize']" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-alert :show="!!errorMessage" variant="danger">{{
                                          errorMessage
                                          }}</b-alert>
                    <div v-for="(statement, index) in endorsementStatements" :key="index" class="mt-2">
                      <div class="form-group row">
                        <label class="col-md-8 col-form-label">Statement #{{ index + 1 }}</label>
                        <div class="col-md-4 text-right">
                          <b-button variant="outline-danger" @click="deleteStatement(index)"
                            :disabled="statement.isPublished && !isAdmin">Delete
                            <font-awesome-icon :icon="['fas', 'trash-alt']" />
                          </b-button>
                        </div>
                      </div>
                      <b-form-group class="mt-3" v-if="statement.id > 0">
                        <div id="statement" class="statements-text-published" v-html="statement.text"
                          :disabled="statement.isPublished" rows="4" max-rows="6"></div>
                      </b-form-group>
                      <b-form-group class="mt-3" v-else>
                        <ValidationProvider name="Statement" rules="required" v-slot="validationContext">
                          <vue-highlights v-model="statement.text" :extractUrlsWithoutProtocol="true"
                            :state="getValidationState(validationContext)" :disabled="statement.isPublished"
                            placeholder="Enter your non-candidate statement" />

                          <b-form-invalid-feedback>{{
                                                      validationContext.errors[0]
                                                      }}</b-form-invalid-feedback>
                        </ValidationProvider>
                      </b-form-group>
                    </div>
                    <hr class="my-4" />
                    <b-button @click="addStatement" variant="primary">Add Statement
                      <font-awesome-icon :icon="['fas', 'plus']" />
                    </b-button>

                    <b-button v-show="false" ref="submit" type="submit" variant="primary">Submit</b-button>
                  </b-form>
                </b-card-body>
              </b-card>
              <AdminFooterButton @on-save="onSave" @on-skip="onSkip" @on-back="onBack" @on-preview="onPreview"
                :saving="saving" :invalid="invalid" :hide-save-button="!showSaveButton" :hide-skip-button="!hasStatements"
                :hide-dashboard-button="!showDashboardButton" :saveTextButton="saveButtonText" />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide theme-id="endorsementStatement" :template-id="candidateModel.profileTheme" />
  </div>
</template>

<script>
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
export default {
  name: "AddEndorsement",
  mixins: [DirtyFlagMixin, CandidateMixin],
  data() {
    return {
      endorsementStatements: [],
      input_value: ""
    };
  },
  created() {
    /*this.endorsementStatements*/ const statements =
      this.candidateModel.endorsementStatements;
    statements.map((s) => {
      const words = s.text.split(" ");
      const textArray = words.map((w) => {
        if (w.startsWith("#")) {
          w = `<span class="highlights"><a href="#">${w}</a></span>`;
        }
        return w;
      });
      s.text = textArray.join(" ");
    });
    this.endorsementStatements = statements;
  },
  computed: {
    saveButtonText(){
      var text = "Save"
      if(this.candidateModel.manageEndorsementList.length > 0)
        text = "Save & Continue"

      return text  
    },
    hasStatements() {
      if(this.candidateModel.manageEndorsementList.length == 0) return false;
          
      return this.candidateModel.endorsementStatements.length > 0;
    },
    showDashboardButton() {
      if (this.endorsementStatements.length) return false;
      else if (this.hasSubmittedItems) return false;
      else if (this.candidateModel.isPetition) return false;
      else if (this.candidateModel.endorsementStatements.length > 0)
        return false;
      return true;
    },
    showSaveButton() {
      if (this.endorsementStatements.length > 0) return true;
      if (this.candidateModel.endorsementStatements.length > 0) return true;

      return false;
    },
    isAdmin() {
      return this.$store.state.auth.user.roles.includes("Admin");
    }
  },
  methods: {
    onPreview() {},
    onBack() {
      if (this.candidateModel.events.length > 0)
        this.$router.push("manage-events");
      else this.$router.push("add-event");
    },
    onSkip() {
      if (this.hasStatements) this.$router.push("manage-endorsements");
      else if (this.hasSubmittedItems) this.$router.push("manage-endorsements");
      else if (this.candidateModel.isPetition)
        this.$router.push("manage-petition");
      else this.$router.push("dashboard");
    },
    onSave() {
      this.$refs.submit.click();
    },
    addStatement() {
      this.endorsementStatements.push({
        id: 0,
        text: null,
        number: this.endorsementStatements.length + 1,
      });
    },
    deleteStatement(index) {
      this.endorsementStatements.splice(index, 1);
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        statements: this.endorsementStatements,
      };

      this.isSubmitting = true;
      this.saving = true;     
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.candidateService
            .SaveEndorsementTextAsync(payload)
            .then((res) => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if (fromBreadcrumb != true) {
                  if (this.candidateModel.manageEndorsementList.length > 0)
                    this.$router.push("manage-endorsements");
                }
              }, 500);
            })
            .catch((e) => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-wizard {
  .card-body {
    .btn-outline-danger {
      border-width: 0;
    }
  }
}

.hashed {
  font-weight: bold;
  color: blue;
}

.statements-text-published,
::v-deep .highlights__body-container div {
  resize: none;
  overflow-y: scroll;
  height: 110px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
}

::v-deep .highlights {
  color: #0069d9 !important;
  font-weight: bold !important;
}

::v-deep .highlights__placeholder {
  color: #ccc;
  position: absolute;
  top: 0.375rem;
  left: 0.75rem;
  z-index: 1;
}
</style>
